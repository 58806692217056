import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "axios";
import moment from "moment/moment";
import { updateOrders } from "../../../redux/productOrder/productOrderSlice";

const CancelOrder = () => {
    const { isAuth } = useSelector((store) => store.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuth) {
            navigate("/");
        }
    }, []);
    const [loadData, setLoadData] = useState([]);
    const [selectBrand, setSelectBrand] = useState("");
    const [cancelmodel, setCancelModel] = useState(false);
    const [selectOrder, setSelectOrder] = useState("")

    const dispatch = useDispatch();

    const { cancelOrder } = useSelector(
        (store) => store.productOrder
    );

    useEffect(() => {
        setLoadData(cancelOrder);
    }, [cancelOrder]);

    const columns = [
        {
            field: "id",
            headerName: "S. No",
            filterable: false,
            renderCell: (params) => params.rowIndex + 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "orderId",
            headerName: "OrderId",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "clientname",
            headerName: "Clientname",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "Order DateTime",
            width: 150,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column">
                        <h6>{moment(params.row.createdAt).format("MMM/DD/YYYY")}</h6>
                        <h6>{moment(params.row.createdAt).format("h:mm:ss a")}</h6>
                    </div>
                );
            },
        },
        {
            field: "shippingInfo",
            headerName: "Address",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column">
                        <div>
                            {params.row.shippingInfo.address}
                        </div>
                        <div>
                            {params.row.shippingInfo.city}
                        </div>
                        <div>
                            {params.row.shippingInfo.state}
                        </div>
                    </div>
                );

            }
        },
        {
            field: "totalAmount",
            headerName: "Total Amount",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "Payment mode",
            headerName: "Payment mode",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column">
                        <div>
                            {params.row.paymentInfo.mode}
                        </div>
                    </div>
                );

            }
        },
        {
            field: "Status",
            headerName: "Status",
            width: 200,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto flex-column">
                        <div>
                            {params.row.statusText}
                        </div>
                    </div>
                );

            }
        },
        {
            field: "clientPhone",
            headerName: "Client Phone",
            width: 200,
            headerAlign: "center",
            align: "center",
        }
    ];

    return (
        <>
            <Fragment>
                <div className="content-wrapper ">
                    <div
                        style={{ height: 650, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <DataGrid
                            rows={loadData}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pageSize={10}
                            rowHeight={100}
                            getRowHeight={() => "auto"}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                    <div class="modal fade" id="processModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <h3>{`Do you want to Cancel this orderId`}</h3>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal fade" id="cancelModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <h3>Do you want to Cancel this orderId:</h3>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Fragment>
            {/* )} */}
        </>
    );
};

export default CancelOrder;
