import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const Dashboard = () => {
  const navigate = useNavigate();
  const { isAuth } = useSelector((store) => store.auth);
  const { dispatchOrder } = useSelector(
    (store) => store.productOrder
);
  return (
    <>
      {isAuth ? (
        <Fragment>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Dashboard</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a>Home</a>
                      </li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* <Row className="g-2  mb-4 mt-4 ms-2 w-50 d-flex">
              <div className="d-flex">
                <Col md>
                  From <Form.Control type="date" />
                </Col>
                <Col md>
                  To <Form.Control type="date" />
                </Col>
                <Col md>
                  <button className="btn btn-primary mt-4">Search</button>
                </Col>
              </div>
            </Row> */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-2 col-6">
                    <div
                      className="small-box bg-info"
                      style={{ padding: "10px" }}
                    >
                      <div className="inner">
                        <h4>{dispatchOrder.length}</h4>
                        <p style={{ height: "40px", marginTop: "10px" }}>
                          OutForDeliver Orders
                        </p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag " />
                      </div>
                      <a className="small-box-footer">
                        <Link
                          to="/admin/outfordeliver"
                          style={{ color: "#fff" }}
                        >
                          More info <i className="fas fa-arrow-circle-right" />
                        </Link>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fragment>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Dashboard;
