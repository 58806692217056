import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";

const CourierPriceList = () => {
    const { isAuth } = useSelector((store) => store.auth);
    const { courierPrices } = useSelector((store) => store.courierPrices)
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuth) {
            navigate("/");
        }
    }, []);
    const [loadData, setLoadData] = useState([]);
    const [selectBrand, setSelectBrand] = useState("");
    const [cancelmodel, setCancelModel] = useState(false);

    const dispatch = useDispatch();
    useEffect(() => {
        const courierPrice = [...courierPrices];
        setLoadData(courierPrice);
    }, [courierPrices]);

    // const deleteClick = async (e) => {
    //     const data = await dispatch(brandDelete(selectBrand._id));
    //     setCancelModel(false);
    //     // if (data.payload.success) {
    //     //   alert("Category Deleted");
    //     //   window.location.reload(false);
    //     // } else {
    //     //   alert(data.message);
    //     // }
    // };

    const cancelClick = (e, brand) => {
        setCancelModel(true);
        setSelectBrand(brand);
    };
    const statusChange = async (e, data) => {
        e.preventDefault();
        const value = e.target.checked;

        if (data) {
            const formData = {
                catid: data._id,
                status: value,
            };
            // const updateBrand = await dispatch(brandUpdate(formData));
        }
    };

    // const priorityChange = async (e, data) => {
    //   e.preventDefault();
    //   const value = e.target.checked;

    //   if (data) {
    //     const formData = {
    //       catid: data._id,
    //       priority: value,
    //     };
    //     const updateCat = await dispatch(categoryUpdate(formData));
    //   }
    // };
    const columns = [
        {
            field: "id",
            headerName: "S. No",
            filterable: false,
            renderCell: (params) => loadData.findIndex(row => row._id === params.row._id) + 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "country",
            headerName: "COUNTRY NAME",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "couriersName",
            headerName: "COUNTRY TYPE",
            width: 150,
            headerAlign: "center",
            align: "center",

        },
        {
            field: "weight",
            headerName: "WEIGHT",
            width: 150,
            headerAlign: "center",
            align: "center",

        },
        {
            field: "price",
            headerName: "PRICE",
            width: 150,
            headerAlign: "center",
            align: "center",

        },
        {
            field: "ACTION",
            width: 240,
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto my-2">
                        <Link to={`/admin/editcourierprice/${params.row._id}`}>
                            <Button variant="contained" color="primary">
                                EDIT
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
        // {
        //     field: "ACTION.",
        //     width: 240,
        //     headerAlign: "center",
        //     renderCell: (cellValues) => {
        //         return (
        //             <div className="d-flex m-auto">
        //                 <Button
        //                     variant="contained"
        //                     // onClick={(e) => cancelClick(e, cellValues.row)}
        //                     color="error"
        //                 >
        //                     Delete
        //                 </Button>
        //             </div>
        //         );
        //     },
        // },
    ];

    return (
        <>
            {/* {categoryLoading ? (
        <div className="load">
          <ClipLoader color={"#D0021B"} loading={categoryLoading} size={30} />
        </div>
      ) : ( */}
            <Fragment>
                <div className="content-wrapper ">
                    <div
                        style={{ height: 650, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <DataGrid
                            rows={loadData}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pageSize={10}
                            rowHeight={100}
                            getRowHeight={() => "auto"}
                            rowsPerPageOptions={[10]}
                        // checkboxSelection
                        />
                    </div>

                    <Modal
                        size="md"
                        isOpen={cancelmodel}
                        toggle={() => setCancelModel(!cancelmodel)}
                    >
                        <ModalHeader>
                            <div className=" ">
                                <div className="col-12 ">
                                    <div className="col-12">
                                        <h3>
                                            Do You Want to{" "}
                                            <span style={{ color: "red" }}>Delete</span>{" "}
                                            {selectBrand !== "" ? (
                                                <>
                                                    <span
                                                        style={{
                                                            color: "#dc3545",
                                                        }}
                                                    >
                                                        {selectBrand.name}
                                                    </span>
                                                </>
                                            ) : (
                                                <></>
                                            )}{" "}
                                            Brand
                                        </h3>
                                        <div className="col-12 mt-3 ">
                                            <div className="col-6 d-flex justify-content-between">
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    className="mx-1 px-5"
                                                    onClick={() => setCancelModel(false)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="mx-1 px-5"
                                                // onClick={(e) => deleteClick(e)}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                    </Modal>
                </div>
            </Fragment>
            {/* )} */}
        </>
    );
};

export default CourierPriceList;
