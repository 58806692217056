import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  orderList: [],
  orderDetail: {},
  processingOrder: [],
  cancelOrder: [],
  dispatchOrder: [],
  deliveredOrder:[],
  isLoading: false,
};

export const getOrders = createAsyncThunk(
  "order/clientOrders",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/productorder/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Orders not found");
    }
  }
);
export const orderDetails = createAsyncThunk(
  "order/orderDetail",
  async (_id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/productorder/${_id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Order details not found");
    }
  }
);

export const updateOrders = createAsyncThunk(
  "orders/updateorders",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/productorder/updateorder/${formData.orderId}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Order not updated");
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        state.orderList = action.payload.productOrders.filter(
          (order) => order.status === 1
        );
        state.processingOrder = action.payload.productOrders.filter(
          (order) => order.status === 2
        );
        state.dispatchOrder = action.payload.productOrders.filter(
          (order) => order.status === 3
        );
        state.deliveredOrder = action.payload.productOrders.filter(
          (order) => order.status === 4
        );
        state.cancelOrder = action.payload.productOrders.filter(
          (order) => order.status === 5
        );
        state.isLoading = false;
      })
      .addCase(getOrders.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(orderDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(orderDetails.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.orderDetail = action.payload.productOrder;
        }
        state.isLoading = false;
      })
      .addCase(orderDetails.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateOrders.fulfilled, (state, action) => {
        if (action.payload.productOrder.status === 1) {
          state.orderList = state.orderList.filter(
            (order) => order._id !== action.payload.productOrder._id
          );
          state.orderList = [action.payload.productOrder, ...state.orderList];
        }
        if (action.payload.productOrder.status === 2) {
          state.orderList = state.orderList.filter(
            (order) => order._id !== action.payload.productOrder._id
          );
          state.processingOrder = [
            action.payload.productOrder,
            ...state.processingOrder,
          ];
        }
        if (action.payload.productOrder.status === 3) {
          state.processingOrder = state.processingOrder.filter(
            (order) => order._id !== action.payload.productOrder._id
          );
          state.dispatchOrder = [
            action.payload.productOrder,
            ...state.dispatchOrder,
          ];
        }
        if (action.payload.productOrder.status === 4) {
          console.log(action.payload.productOrder,"action.payload.productOrder");
          state.dispatchOrder = state.dispatchOrder.filter(
            (order) => order._id !== action.payload.productOrder._id
          );
          state.deliveredOrder = [
            action.payload.productOrder,
            ...state.deliveredOrder,
          ];
        }
        if (action.payload.productOrder.status === 5) {
          state.cancelOrder = [
            action.payload.productOrder,
            ...state.cancelOrder,
          ];
        }
        state.isLoading = false;
      })
      .addCase(updateOrders.rejected, (state) => {
        state.isLoading = true;
      });
  },
});

export default orderSlice.reducer;
