import React, { useEffect } from "react";
import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { categoryPost } from "../../../redux/category/categorySlice";

const BulkCategoryUploade = () => {
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");
  const dispatch = useDispatch();

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    var date = new Date();
    date.setDate(date.getDate() + 7);
    for (let i in data) {
      let myrow = data[i];
      numbers = i;




      const formData = {
        catname: myrow.catname,
        slugUrl: myrow.slugUrl,
        catId: myrow.catid,
        mblimg: `https://mystore.ewshopping.com/admin/Category/Desktop/${myrow.mblimg}`,
        icon: `https://mystore.ewshopping.com/admin/Category/Desktop/${myrow.mblimg}`,
        dskimg: `https://mystore.ewshopping.com/admin/Category/Mobile/${myrow.dskimg}`,
        banner: `https://mystore.ewshopping.com/admin/Category/Mobile/${myrow.dskimg}`,
        metaTitle:myrow.title,
        metaDesc:myrow.description,
        metaKeyword:myrow.keyword,
      };
      const uploadProducts = await dispatch(categoryPost(formData));

      if (uploadProducts.payload.success) {
        setUploadedProductName(
          `${Number(numbers) + 1}) ${myrow.catname}  upload successfull`
        );
      } else {
      }
    }
  };

  return (
    <div className="content-wrapper">
      <div className="employeesContainer">
        <form onSubmit={handlesubmit}>
          <div className="card m-4">
            <div className="card-footer">
              <h5>Category Bulk Upload</h5>
            </div>
            <div className="card-body">
              <Row className="g-2  m-2">
                <Col md>
                  <Form.Label htmlFor="disabledTextInput">
                    Upload Excel File *
                  </Form.Label>

                  <Form.Control
                    type="file"
                    placeholder="enter name....."
                    onChange={(e) => handlefile(e)}
                  />
                </Col>
                <Col md>
                  <button
                    className="btn btn-primary me-2 mt-4 ml-5 "
                    type="submit"
                  >
                    Category Bulk Upload
                  </button>
                </Col>
              </Row>

              <p style={{ color: "green" }} className="mt-2">
                {uploadedProductName}
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BulkCategoryUploade;
