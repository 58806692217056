import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";

const ListCourier = () => {
    const { isAuth } = useSelector((store) => store.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuth) {
            navigate("/");
        }
    }, []);
    const [loadData, setLoadData] = useState([]);
    const [selectBrand, setSelectBrand] = useState("");
    const [cancelmodel, setCancelModel] = useState(false);

    const dispatch = useDispatch();

    const { courierList } = useSelector(
        (store) => store.courierList
    );

    useEffect(() => {
        setLoadData(courierList);
    }, [courierList]);

    

    const deleteClick = async (e) => {
        setCancelModel(false);
    };

    const cancelClick = (e, brand) => {
        setCancelModel(true);
        setSelectBrand(brand);
    };


    const columns = [
        
        {
            field: "awb",
            headerName: "AWB No.",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "consignee",
            headerName: "Consignee Name.",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "ACTION",
            width: 240,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                return (
                    <div className="d-flex m-auto my-2">
                        <Link to={`/admin/edit-courier/${params.row.awb}`}>
                            <Button variant="contained" color="primary">
                                EDIT
                            </Button>
                        </Link>
                    </div>
                );
            },
        },
        // {
        //     field: "ACTION.",
        //     width: 240,
        //     headerAlign: "center",
        //     align: "center",
        //     renderCell: (cellValues) => {
        //         return (
        //             <div className="d-flex m-auto">
        //                 <Button
        //                     variant="contained"
        //                     onClick={(e) => cancelClick(e, cellValues.row)}
        //                     color="error"
        //                 >
        //                     Delete
        //                 </Button>
        //             </div>
        //         );
        //     },
        // },
    ];

    return (
        <>
            <Fragment>
                <div className="content-wrapper ">
                    <div
                        style={{ height: 650, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <DataGrid
                            rows={loadData}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pageSize={10}
                            rowHeight={100}
                            getRowHeight={() => "auto"}
                            rowsPerPageOptions={[10]}
                        />
                    </div>

                    <Modal
                        size="md"
                        isOpen={cancelmodel}
                        toggle={() => setCancelModel(!cancelmodel)}
                    >
                        <ModalHeader>
                            <div className=" ">
                                <div className="col-12 ">
                                    <div className="col-12">
                                        <h3>
                                            Do You Want to{" "}
                                            <span style={{ color: "red" }}>Delete</span>{" "}
                                            {selectBrand !== "" ? (
                                                <>
                                                    <span
                                                        style={{
                                                            color: "#dc3545",
                                                        }}
                                                    >
                                                        {selectBrand.name}
                                                    </span>
                                                </>
                                            ) : (
                                                <></>
                                            )}{" "}
                                            Courier
                                        </h3>
                                        <div className="col-12 mt-3 ">
                                            <div className="col-6 d-flex justify-content-between">
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    className="mx-1 px-5"
                                                    onClick={() => setCancelModel(false)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className="mx-1 px-5"
                                                    onClick={(e) => deleteClick(e)}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalHeader>
                    </Modal>
                </div>
            </Fragment>
            {/* )} */}
        </>
    );
};

export default ListCourier;
