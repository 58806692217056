import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  courierList: [],
  courierEcomList:[],
  courierLoading: false,
  isLoading: false,
  ecomIsLoading:false
};

export const courierPost = createAsyncThunk(
  "courier/courierpost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/courier/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier not created");
    }
  }
);

export const getCourierList = createAsyncThunk(
  "courier/courierlist",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/courier/all`;
      const resp = await axios.get(url);
      return resp.data.courier;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 not found");
    }
  }
);
export const getEcomCourierList = createAsyncThunk(
  "courier/ecomcourierlist",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/courier/getecomcourierlist`;
      const resp = await axios.get(url);
      return resp.data.courier;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 not found");
    }
  }
);

export const getSingleCourierList = createAsyncThunk(
  "courier/singleCourier",
  async (formData, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/courier/courierdetail/${formData.awb}`;
      const resp=await axios.get(url);
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier not found");
    }
  }
);

export const updateCourier = createAsyncThunk(
  "courier/courierupdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };

      const url = `${Baseurl}/api/v1/courier/updateby/${formData.awb}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier didn't update");
    }
  }
);

export const deleteCourier = createAsyncThunk(
  "courier/courierdelet",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/courier/rejectedby/${formData.awb}`;
      const resp = await axios.delete(url, formData, config);

      const myreturn = {
        success: resp.data.success,
        // awb: awb,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier not deleted");
    }
  }
);

const courierListSlice = createSlice({
  name: "CourierList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(courierPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(courierPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.isLoading = false;
          state.courierList = [ action.payload.courier,...state.courierList];
        }
      })
      .addCase(courierPost.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourierList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourierList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.courierList = action.payload
      })
      .addCase(getCourierList.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getEcomCourierList.pending, (state) => {
        state.ecomIsLoading = true;
      })
      .addCase(getEcomCourierList.fulfilled, (state, action) => {
          state.ecomIsLoading = false;
          state.courierEcomList = action.payload
      })
      .addCase(getEcomCourierList.rejected, (state) => {
        state.ecomIsLoading = true;
      })
      .addCase(updateCourier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCourier.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.courierList = state.courierList.filter(
            (courier) => courier.amb !== action.payload.courier.amb
          );
          state.courierList = [...state.courierList, action.payload.courier];
        }

        state.isLoading = false;
      })
      .addCase(updateCourier.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCourier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCourier.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.courierList = state.courierList.filter(
            (courier) => courier.amb !== action.payload.awb
          );
        }
        state.isLoading = false;
      })
      .addCase(deleteCourier.rejected, (state, action) => {
        state.isLoading = true;
      });
  },
});

export default courierListSlice.reducer;
