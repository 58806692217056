import React, { Fragment, useEffect, useState } from 'react';
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { courierPricePost, courierPriceUpdate } from '../../../redux/courierRate/courierSlice';
import { useParams } from 'react-router-dom';
import { Baseurl } from '../../../config/BaseUrl';
import axios from 'axios';

const EditCourierprice = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const { courierPriceLoading } = useSelector((store) => store.courierPrices)
    const [countryList] = useState(["USA", "CANADA"]);
    const [courierType] = useState(["DX", "NDX"]);
    const [weights] = useState([
        "0.5", "1.0", "1.5", "2.0", "2.5", "3.0", "3.5", "4.0", "4.5", "5.0",
        "5.5", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0",
        "10.5", "11.0", "12.5", "13.0", "13.5", "14.0", "14.5", "15.0", "15.5",
        "16.0", "16.5", "17.0", "17.5", "18.0", "18.5", "19.0", "19.5", "20.0",
        "20.5", "21.0", "21.5", "22.0", "22.5", "23.0", "23.5", "24.0", "24.5",
        "25.0", "25.5", "26.0", "26.5", "27.0", "27.5", "28.0", "28.5", "29.0",
        "29.5", "30.0", "31 - 50", "51 - 70"
    ]);
    const [availableCountryList, setAvailableCountryList] = useState("")
    const [availableCourierType, setAvailableCourierType] = useState("")
    const [availableWeights, setAvailableWeights] = useState("")
    const [courierPrice, setCourierPrice] = useState("")

    useEffect(() => {
        const fetchPrice = async () => {
            const url = `${Baseurl}/api/v1/courierprice/${params._id}`
            const resp = await axios.get(url);
            if (resp.data.success) {
                setAvailableCountryList(resp.data.courierPrice.country)
                setAvailableCourierType(resp.data.courierPrice.couriersName)
                setAvailableWeights(resp.data.courierPrice.weight)
                setCourierPrice(resp.data.courierPrice.price)
            }
        }
        fetchPrice()
    }, [params._id])

    const selectCountry = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setAvailableCountryList(text)
    }

    const selectCourierType = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setAvailableCourierType(text)
    }


    const selectWeight = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setAvailableWeights(text)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (availableCountryList !== "" && availableCourierType !== "" && availableWeights !== "" && courierPrice !== "") {
            const formData = {
                _id: params._id,
                weight: Number(availableWeights),
                price: Number(courierPrice),
                country: availableCountryList,
                couriersName: availableCourierType
            }
            const data = await dispatch(courierPriceUpdate(formData));
            if (data.payload.success) {
                toast.success("Courier Price Updated", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    zIndex: 11000,
                });
                setAvailableCountryList("")
                setAvailableCourierType("")
                setAvailableWeights("")
                setCourierPrice("")
            }
            else {
                toast.error("Please check the fields", {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    zIndex: 11000,
                });
            }

        } else if (availableCountryList === "") {
            alert("Pls select country name")
        } else if (availableCourierType === "") {
            alert("Pls select courier type")
        } else if (availableWeights === "") {
            alert("Pls select courier weight")
        } else if (courierPrice === "") {
            alert("Pls fill price details")
        }
    }

    return (
        <>
            <Fragment>
                <div className="content-wrapper">
                    <div className="employeesContainer">
                        <form onSubmit={handleSubmit}>
                            <h4 className="p-4">Edit Courier Price</h4>
                            <div className="card m-4">
                                <div className="card-footer">
                                    <h5>Edit Country Price</h5>
                                </div>
                                <div className="card-body">
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Country <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Floating label select example"

                                                onChange={(e) => {
                                                    selectCountry(e)
                                                }}
                                            >
                                                <option value="0">{"Select Country"}</option>
                                                {countryList.map((country, index) => (
                                                    <option
                                                        selected={country === availableCountryList}
                                                        key={index}
                                                        value={availableCountryList}
                                                        name={country}
                                                        required
                                                    >
                                                        {country}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Courier Type <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Floating label select example"
                                                onChange={(e) => {
                                                    selectCourierType(e)
                                                }}
                                            >
                                                <option value="0">{"Select Courier Type"}</option>
                                                {courierType.map((courier, index) => (
                                                    <option
                                                        key={index}
                                                        selected={courier === availableCourierType}
                                                        value={availableCourierType}
                                                        name={courier}
                                                        required
                                                    >
                                                        {courier}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    <Row className="g-2  m-2 ">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Weight <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Floating label select example"
                                                onChange={(e) => {
                                                    selectWeight(e)
                                                }}
                                            >
                                                <option value="0">{"Select Courier Type"}</option>
                                                {weights.map((weight, index) => (
                                                    <option
                                                        key={index}
                                                        selected={weight === availableWeights}
                                                        value={availableWeights}
                                                        name={weight}
                                                        required
                                                    >
                                                        {weight}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Courier Price
                                                <span style={{ color: "red" }}>*</span>
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Courier Price"
                                                value={courierPrice}
                                                required
                                                onChange={(e) => {
                                                    setCourierPrice(e.target.value)
                                                }}

                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-footer text-center ">
                                    {availableCountryList !== "" && availableCourierType !== "" && availableCourierType !== "" && courierPrice !== "" ? (
                                        <button className="btn btn-primary me-2 " type="submit">
                                            Add Product
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-primary me-2 "
                                            disabled
                                            type="submit"
                                        >
                                            Add Product
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Fragment>
            <ToastContainer/>
        </>
    )
}

export default EditCourierprice