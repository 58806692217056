import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  brandtotal: [],
  brandicon: "",
  brandbanner: "",
  branddesktop: "",
  brandthumb: "",
  isbrandbanLoading: true,
  isbranddeskLoading: true,
  isbrandIconLoading: true,
  isbrandthumbLoading: true,
  isLoading: true,
  brandLoading: true,
  delbrandLoading: true,
};

export const getBrand = createAsyncThunk("brand/getBrand", async (thunkAPI) => {
  try {
    const url = `${Baseurl}/api/v1/brand/all`;
    const resp = await axios(url);
    return resp.data.brands;
  } catch (error) {
    return thunkAPI.rejectWithValue("404 Not Found");
  }
});

export const brandPost = createAsyncThunk(
  "brand/brandPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/brand/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

export const validatebrandSlugUrl = createAsyncThunk(
  "brand/validatebrandSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    }
    try {
      const url = `${Baseurl}/api/v1/brand/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

export const brandUpdate = createAsyncThunk(
  "brand/brandUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/brand/update/${formData.brandid}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);

export const brandDelete = createAsyncThunk(
  "brand/brandDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/brand/delete/${id}`;
      const resp = await axios.delete(url, id, config);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand Not create");
    }
  }
);


export const brandAllimgageUploade = createAsyncThunk(
  "brand/brandNewimg",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/brand/newimages`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("brand desktopIcon Not create");
    }
  }
);

const BrandSlice = createSlice({
  name: "brandAll",
  initialState,
  reducers: {
    updateBrandIcon(state, action) {
      state.brandicon = action.payload;
      state.isbrandIconLoading = false;
    },
    updateBrandThumbnail(state, action) {
      state.brandthumb = action.payload;
      state.isbrandthumbLoading = false;
    },
    updateBrandBanner(state, action) {
      state.brandbanner = action.payload;
      state.isbrandbanLoading = false;
    },
    updateBrandDesktop(state, action) {
      state.branddesktop = action.payload;
      state.isbranddeskLoading = false;
    },
    resetBrandImage(state) {
      state.brandicon = "";
      state.brandbanner = "";
      state.branddesktop = "";
      state.brandthumb = "";
      state.isbrandbanLoading = true;
      state.isbranddeskLoading = true;
      state.isbrandIconLoading = true;
      state.isbrandthumbLoading = true;
    }
  },
  extraReducers:(builder)=> {
    builder 
    .addCase(getBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrand.fulfilled, (state,action) => {
          state.brandtotal = action.payload
          state.isLoading = false;
          state.brandLoading = false;
      })
      .addCase(getBrand.rejected, (state,action) => {
        state.isLoading = true;
      })
      .addCase(brandPost.pending, (state) => {
        state.brandLoading = true;
      })
      .addCase(brandPost.fulfilled, (state,action) => {
          if (action.payload.success) {
            state.brandtotal = [...state.brandtotal, action.payload.brand].sort();
          }
          state.brandicon = "";
            state.brandbanner = "";
            state.branddesktop = "";
            state.brandthumb = "";
          state.brandLoading = false;
      })
      .addCase(brandPost.rejected, (state,action) => {
        state.brandLoading = true;
      })
      .addCase(brandUpdate.pending, (state) => {
        state.brandLoading = true;
      })
      .addCase(brandUpdate.fulfilled, (state,action) => {
          if (action.payload.success) {
            state.brandtotal = state.brandtotal.filter(
              (brand) => brand._id !== action.payload.brand._id
            );
            state.brandtotal = [...state.brandtotal, action.payload.brand].sort();
            state.brandicon = "";
            state.brandbanner = "";
            state.branddesktop = "";
            state.brandthumb = "";
            state.isbrandbanLoading = true;
            state.isbranddeskLoading = true;
            state.isbrandIconLoading = true;
            state.isbrandthumbLoading = true;
          }
          state.brandLoading = false;
      })
      .addCase(brandUpdate.rejected, (state,action) => {
        state.brandLoading = true;
      })
      .addCase(brandDelete.pending, (state) => {
        state.delbrandLoading = true;
      })
      .addCase(brandDelete.fulfilled, (state,action) => {
          if (action.payload.success) {
            state.brandtotal = state.brandtotal.filter(
              (brand) => brand._id !== action.payload.id
            ).sort();
          }
          state.delbrandLoading = false;
      })
      .addCase(brandDelete.rejected, (state,action) => {
        state.delbrandLoading = true;
      })
      .addCase(brandAllimgageUploade.pending, (state) => {
        state.isbrandthumbLoading = true;
      })
      .addCase(brandAllimgageUploade.fulfilled, (state,action) => {
          if (action.payload.success) {
            state.brandthumb = action.payload.thumbnails;
            state.brandicon = action.payload.icons;
            state.brandbanner = action.payload.banners;
            state.branddesktop = action.payload.desktopIcon;
          }
          state.isbrandthumbLoading = false;
          state.isbrandIconLoading = false;
          state.isbrandbanLoading = false;
          state.isbranddeskLoading = false;
      })
      .addCase(brandAllimgageUploade.rejected, (state,action) => {
        state.isbrandthumbLoading = true;
      })
  },
});
export const {
  updateBrandIcon,
  updateBrandThumbnail,
  updateBrandBanner,
  updateBrandDesktop,
  resetBrandImage
} = BrandSlice.actions;
export default BrandSlice.reducer;
