import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";

const RemovedEnquiry = () => {
    const { isAuth } = useSelector((store) => store.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuth) {
            navigate("/");
        }
    }, []);
    const [loadData, setLoadData] = useState([]);
    const [selectBrand, setSelectBrand] = useState("");
    const [cancelmodel, setCancelModel] = useState(false);

    const dispatch = useDispatch();

    const { deletedRequest } = useSelector(
        (store) => store.CourierRequest
    );

    useEffect(() => {
        setLoadData(deletedRequest);
    }, [deletedRequest]);


    const columns = [
        {
            field: "id",
            headerName: "S. No",
            filterable: false,
            renderCell: (params) => loadData.findIndex(row => row._id === params.row._id) + 1,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "clientName",
            headerName: "clientName",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "clientMobile",
            headerName: "clientMobile",
            width: 200,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "statusText",
            width: 240,
            headerAlign: "center",
            align: "center",
        },
    ];

    return (
        <>
            <Fragment>
                <div className="content-wrapper ">
                    <div
                        style={{ height: 650, width: "100%" }}
                        className="d-flex m-auto text-align-center"
                    >
                        <DataGrid
                            rows={loadData}
                            columns={columns}
                            getRowId={(row) => row._id}
                            pageSize={10}
                            rowHeight={100}
                            getRowHeight={() => "auto"}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                </div>
            </Fragment>
            {/* )} */}
            <ToastContainer />
        </>
    );
};

export default RemovedEnquiry;
