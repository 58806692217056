import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  categorytotal: [],

  caticon: "",
  catbanner: "",
  catdesktop: "",
  catthumb: "",
  isCatbanLoading: true,
  isCatdeskLoading: true,
  isCatIconLoading: true,
  isCatthumbLoading: true,
  isLoading: true,
  categoryLoading: true,
  deleteCatLoading: true,
  checkSlugurl: true,
};

export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/category/all`;
      const resp = await axios(url);
      return resp.data.categories;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);
  export const categoryPost = createAsyncThunk(
    "category/categorypost",
    async (formData, thunkAPI) => {
      try { 
        const config = {
          Headers: { "Content-Type": "application/json" },
        };
        const url = `${Baseurl}/api/v1/category/new`;
        const resp = await axios.post(url, formData, config);

        return resp.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(`category Not create `);
      }
    }
  );

export const categoryUpdate = createAsyncThunk(
  "category/categoryUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/category/update/${formData.catid}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const categoryDelete = createAsyncThunk(
  "category/categoryDelete",
  async (_id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/category/delete/${_id}`;
      const resp = await axios.delete(url, _id, config);

      const myreturn = {
        success: resp.data.success,
        _id: _id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Not create");
    }
  }
);

export const categoryIcon = createAsyncThunk(
  "category/categoryIcon",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/icon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const categoryimgnew = createAsyncThunk(
  "category/categoryimgnew",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/newimg`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("category Icon Not create");
    }
  }
);
export const categoryThumbnail = createAsyncThunk(
  "category/categoryThumbnail",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/category/thumbnail`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Category Thumbnail Not create");
    }
  }
);
export const categoryBanner = createAsyncThunk(
  "category/categoryBanner",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/banner`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("banner Not create");
    }
  }
);
export const categoryDesktop = createAsyncThunk(
  "category/categoryDesktop",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/category/desktopicon`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("desktop Icon Not create");
    }
  }
);

export const validateSlugUrl = createAsyncThunk(
  "category/validateSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new email",
    };
    try {
      const url = `${Baseurl}/api/v1/category/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const CategorySlice = createSlice({
  name: "categoryAll",
  initialState,
  reducers: {
    updateCatIcon(state, action) {
      state.caticon = action.payload;
      state.isCatIconLoading = false;
    },
    updateCatThumbnail(state, action) {
      state.catthumb = action.payload;
      state.isCatthumbLoading = false;
    },
    updateCatBanner(state, action) {
      state.catbanner = action.payload;
      state.isCatbanLoading = false;
    },
    updateCatDesktop(state, action) {
      state.catdesktop = action.payload;
      state.isCatdeskLoading = false;
    },
    deleteCategory(state, action) {
      state.categorytotal = state.categorytotal.filter(
        (category) => category._id !== action.payload
      );
    },
    // resetCategoryImage(state) {
    //   state.caticon = "";
    //   state.catbanner = "";
    //   state.catdesktop = "";
    //   state.catthumb = "";
    //   state.isCatbanLoading = true;
    //   state.isCatdeskLoading = true;
    //   state.isCatIconLoading = true;
    //   state.isCatthumbLoading = true;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.categorytotal = action.payload;
        state.isLoading = false;
        state.categoryLoading = false;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(categoryPost.pending, (state) => {
        state.categoryLoading = true;
      })
      .addCase(categoryPost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.categorytotal = [...state.categorytotal, action.payload.category];
          state.caticon = "";
          state.catbanner = "";
          state.catdesktop = "";
          state.catthumb = "";
          state.isCatbanLoading = true;
          state.isCatdeskLoading = true;
          state.isCatIconLoading = true;
          state.isCatthumbLoading = true;
        }
        state.categoryLoading = false;
        state.checkSlugurl = false;
      })
      .addCase(categoryPost.rejected, (state, action) => {
        state.categoryLoading = true;
      })
      .addCase(categoryUpdate.pending,(state)=>{
          state.categoryLoading = true;
      })
      .addCase(categoryUpdate.fulfilled,(state, action)=>{
        if (action.payload.success) {
            state.categorytotal = state.categorytotal.filter(
              (category) => category._id !== action.payload.category._id
            );
            state.categorytotal = [...state.categorytotal, action.payload.category];
            state.caticon = "";
            state.catbanner = "";
            state.catdesktop = "";
            state.catthumb = "";
            state.isCatbanLoading = true;
            state.isCatdeskLoading = true;
            state.isCatIconLoading = true;
            state.isCatthumbLoading = true;
    
          }
    
          state.categoryLoading = false;
      })
      .addCase(categoryUpdate.rejected,(state,action)=>{
        state.categoryLoading = true;
      })
      .addCase(categoryDelete.pending,(state)=>{
        state.deleteCatLoading = true;
      })
      .addCase(categoryDelete.fulfilled,(state,action)=>{
        if (action.payload.success) {
            state.categorytotal = state.categorytotal.filter(
              (category) => category._id !== action.payload.id
            );
    
    
          }
          state.deleteCatLoading = false;
      })
      .addCase(categoryDelete.rejected,(state,action)=>{
        state.deleteCatLoading = true;
      })
      .addCase(categoryThumbnail.pending,(state)=>{
        state.isCatthumbLoading = true;
      })
      .addCase(categoryThumbnail.fulfilled,(state,action)=>{
        if (action.payload.success) {
          state.catthumb = action.payload.thumbnails;
        }
        state.isCatthumbLoading = false;
      })
      .addCase(categoryThumbnail.rejected,(state,action)=>{
        state.isCatthumbLoading = true;
      })
      .addCase(categoryimgnew.pending,(state)=>{
        state.isCatthumbLoading = true;
      })
      .addCase(categoryimgnew.fulfilled,(state,action)=>{
        if (action.payload.success) {
          state.catthumb = action.payload.thumbnails;
          state.caticon = action.payload.icons;
          state.catdesktop = action.payload.desktopIcon;
        }
        state.isCatthumbLoading = false;
        state.isCatIconLoading = false;
        state.isCatdeskLoading = false;
      })
      .addCase(categoryimgnew.rejected,(state,action)=>{
        state.isCatthumbLoading = true;
      })
      .addCase(categoryIcon.pending,(state)=>{
        state.isCatIconLoading = true;
      })
      .addCase(categoryIcon.fulfilled,(state,action)=>{
        if (action.payload.success) {
          state.caticon = action.payload.icons;
        }
        state.isCatIconLoading = false;
      })
      .addCase(categoryIcon.rejected,(state,action)=>{
        state.isCatIconLoading = true;
      })
      .addCase(categoryBanner.pending,(state)=>{
        state.isCatbanLoading = true;
      })
      .addCase(categoryBanner.fulfilled,(state,action)=>{
        if (action.payload.success) {
          state.catbanner = action.payload.banners;
        }
        state.isCatbanLoading = false;
      })
      .addCase(categoryBanner.rejected,(state)=>{
        state.isCatbanLoading = true;
      })
      .addCase(categoryDesktop.pending,(state)=>{
        state.isCatdeskLoading = true;
      })
      .addCase(categoryDesktop.fulfilled,(state,action)=>{
        if (action.payload.success) {
          state.catdesktop = action.payload.desktopIcon;
        }
        state.isCatdeskLoading = false;
      })
      .addCase(categoryDesktop.rejected,(state)=>{
        state.isCatdeskLoading = true;
      })
    
  },
});

export const {
  updateCatIcon,
  updateCatThumbnail,
  updateCatBanner,
  updateCatDesktop,
  deleteCategory,
  resetCategoryImage,
} = CategorySlice.actions;
export default CategorySlice.reducer;
