import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdOutlineCategory } from "react-icons/md";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../../decoratinelogo.jpg";
import { useSelector } from "react-redux";

const Menu = () => {
  const { isAuth, loginData } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  const [showOrder, setShowOrder] = useState(false);
  const [addEdit, setAddEdit] = useState(false);
  const [addCategoryEdit, setAddCategoryEdit] = useState(false);
  const [addBrandEdit, setAddBrandEdit] = useState(false);
  const [addProductEdit, setAddProductEdit] = useState(false);
  const [addCourierPrcEdit, setAddCourierPrcEdit] = useState(false);
  const [showCourier, setShowCourier] = useState(false);
  const [showEnquiry, setShowEnquiry] = useState(false);
  return (
    <>
      {isAuth ? (
        <div>
          <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* Brand Logo */}
            <Link to="/admin" className="brand-link">
              <img
                src={logo}
                // src='decoratinelogo.jpg'
                alt="Admin"
                className="brand-image img-circle elevation-3 "
                style={{ opacity: ".8" }}
              />
              <span className="brand-text font-weight-light">Super Admin</span>
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
              <nav className="mt-2">
                {/*================================ Dashboard ==========================*/}
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item has-treeview">
                    <Link to="/admin" className="nav-link">
                      <span>
                        <FaHome />
                      </span>
                      <p>Dashboard</p>
                    </Link>
                  </li>
                  {/*============================ product Setting Start ================================*/}

                  <li className="nav-item has-treeview">
                    <a
                      className={
                        addEdit
                          ? "nav-link has-treeview menu-is-opening menu-open"
                          : "nav-link"
                      }
                      onClick={() => setAddEdit(!addEdit)}
                    >
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Add-Edit
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul
                      className={
                        addEdit
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link
                          className={
                            addCategoryEdit
                              ? "nav-link has-treeview menu-is-opening menu-open"
                              : "nav-link"
                          }
                          onClick={() => setAddCategoryEdit(!addCategoryEdit)}
                        >
                          <span>
                            <BiCategoryAlt />
                          </span>
                          <p>
                            Category
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul
                          className={
                            addCategoryEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link to="/admin/addcategory" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add category</p>
                            </Link>
                          </li>
                        </ul>
                        <ul
                          className={
                            addCategoryEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link to="/admin/listcategory" className="nav-link">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List category</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            addBrandEdit
                              ? "nav-link has-treeview menu-is-opening menu-open"
                              : "nav-link"
                          }
                          onClick={() => setAddBrandEdit(!addBrandEdit)}
                        >
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Brand
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul
                          className={
                            addBrandEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/addbrand">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Brand</p>
                            </Link>
                          </li>
                        </ul>
                        <ul
                          className={
                            addBrandEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/listbrand">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Brand</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            addProductEdit
                              ? "nav-link has-treeview menu-is-opening menu-open"
                              : "nav-link"
                          }
                          onClick={() => setAddProductEdit(!addProductEdit)}
                        >
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Product
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul
                          className={
                            addProductEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/add-products">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Product</p>
                            </Link>
                          </li>
                        </ul>
                        <ul
                          className={
                            addProductEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link className="nav-link" to="/admin/listproduct">
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>List Product</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={
                            addCourierPrcEdit
                              ? "nav-link has-treeview menu-is-opening menu-open"
                              : "nav-link"
                          }
                          onClick={() =>
                            setAddCourierPrcEdit(!addCourierPrcEdit)
                          }
                        >
                          <span>
                            <TbBrandReactNative />
                          </span>
                          <p>
                            Courier Prices
                            <i className="right fas fa-angle-down arrowsize"></i>
                          </p>
                        </Link>
                        <ul
                          className={
                            addCourierPrcEdit
                              ? "nav nav-treeview productDesign d-block"
                              : "nav nav-treeview productDesign"
                          }
                        >
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="/admin/courierprices-upload"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Add Courier Price</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="/admin/courierpricelist"
                            >
                              <span>
                                <MdOutlineLibraryAdd />
                              </span>
                              <p>Courier Price List</p>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link
                      // className="nav-link"
                      className={
                        showOrder
                          ? "nav-link has-treeview menu-is-opening menu-open"
                          : "nav-link"
                      }
                      style={{
                        transition: !showOrder ? "all 0.6s ease" : "",
                      }}
                      onClick={() => setShowOrder(!showOrder)}
                    >
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Order
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul
                      className={
                        showOrder
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listorders">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Orders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showOrder
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/processorders">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Processed Orders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showOrder
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/dispatchorders">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Dispatch Orders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showOrder
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/deliveredorders">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Delivered Orders</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showOrder
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/cancelorder">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Cancel Orders</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className={
                        showCourier
                          ? "nav-link has-treeview menu-is-opening menu-open"
                          : "nav-link"
                      }
                      onClick={() => setShowCourier(!showCourier)}
                    >
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Courier
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul
                      className={
                        showCourier
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/courier">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Courier</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showCourier
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link to="/admin/listcourier" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Courier</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item ">
                    <a
                      className={
                        showEnquiry
                          ? "nav-link has-treeview menu-is-opening menu-open"
                          : "nav-link"
                      }
                      onClick={() => setShowEnquiry(!showEnquiry)}
                    >
                      <span>
                        <RiUserSettingsFill />
                      </span>
                      <p>
                        Enquiry
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </a>
                    <ul
                      className={
                        showEnquiry
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link className="nav-link" to="/admin/listenquiry">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Enquiry List</p>
                        </Link>
                      </li>
                    </ul>
                    <ul
                      className={
                        showEnquiry
                          ? "nav nav-treeview productDesign d-block"
                          : "nav nav-treeview productDesign"
                      }
                    >
                      <li className="nav-item">
                        <Link to="/admin/viewedenquiry" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Viwed Enquiry</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/removedenquiry" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Removed Enquiry</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                  {/*============================ product Setting end ================================*/}
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      ) : (
        navigate("/")
      )}
    </>
  );
};
export default Menu;
