import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { courierPost } from "../../../redux/addCourier/addCourierSlice";

const AddCourier = () => {
    const { isLoading } = useSelector((store) => store.courierList)
    const [name, setName] = useState("");
    const [destination, setDestination] = useState("");
    const [mobile, setMobile] = useState("");
    const [weight, setWeight] = useState("");
    const [price, setPrice] = useState("");
    const [awb, setAwb] = useState("");
    const dispatch = useDispatch()

    const handleNameChange = async (e) => {
        setName(e.target.value)
    }
    const handleDestinationChange = async (e) => {
        setDestination(e.target.value)
    }
    const handleMobileChange = async (e) => {
        setMobile(e.target.value)
    }


    const handlesubmit = async (e) => {
        e.preventDefault();

        if (name !== "" && mobile !== "" && destination !== "" && price!=="" && weight!=="") {
            const formData = {
                consignee: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
                destination: destination,
                mobile: Number(mobile),
                weight:weight,
                price:price,
                awb:awb,
                type:"Courier"
            }
            const data = await dispatch(courierPost(formData));
            if (!data) {
                alert("Please check the fields")
            } else {
                alert("Courier has been created")
                setName("")
                setDestination("")
                setMobile("")
                setWeight("")
                setPrice("")
            }
        } else {
            alert("Please enter valid details")
        }
    };


    return (
        <>
            <Fragment>
                <div className="content-wrapper">
                    <div className="employeesContainer">
                        <form onSubmit={handlesubmit}>
                            <h4 className="p-4">Add Courier</h4>
                            <div className="card m-4">
                                <div className="card-footer">
                                    <h5>Add Courier</h5>
                                </div>
                                <div className="card-body">
                                    <Row className="g-2  m-2">
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Consignee Name *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Name"
                                                required
                                                value={name}
                                                onChange={(e) => handleNameChange(e)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                        <Col md>
                                            <Form.Label htmlFor="disabledTextInput">
                                                Destination *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Destination"
                                                required
                                                value={destination}
                                                onChange={(e) => handleDestinationChange(e)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2 ">
                                        <Col sm >
                                            <Form.Label htmlFor="disabledTextInput">
                                                Mobile No *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Mobile"
                                                required
                                                value={mobile}
                                                onChange={(e) => handleMobileChange(e)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                        <Col sm >
                                            <Form.Label htmlFor="disabledTextInput">
                                                Product Weight*
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Courier Weight"
                                                required
                                                value={weight}
                                                onChange={(e) => setWeight(e.target.value)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="g-2  m-2 ">
                                        <Col sm >
                                            <Form.Label htmlFor="disabledTextInput">
                                                Price *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Price"
                                                required
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                        <Col sm >
                                            <Form.Label htmlFor="disabledTextInput">
                                                AWB No *
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Price"
                                                required
                                                value={awb}
                                                onChange={(e) => setAwb(e.target.value)}
                                            />
                                            <p style={{ color: "red" }} className="mt-2">

                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="card-footer ">
                                    {
                                        name !== "" && destination !== "" && mobile !== '' && price!='' && weight!=='' && awb!==""? (
                                            <button className="btn btn-primary" type="submit" >
                                                Add Courier
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary" type="submit" disabled>
                                                Add Courier
                                            </button>
                                        )
                                    }

                                </div>
                            </div>
                        </form>
                    </div>
                </div >
            </Fragment >
        </>
    );
};

export default AddCourier;
