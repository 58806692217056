import { useState } from "react";
import { productPost } from "../../../redux/product/productSlice";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { read, utils } from "xlsx";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";

const BulkProductUpload = () => {
    const { categorytotal } = useSelector((store) => store.categoryAll);
    const [category, setCategory] = useState("");
    const [categoryid, setCategoryid] = useState('');
    const [data, setData] = useState([]);
    const [uploadedProductName, setUploadedProductName] = useState("");
    const dispatch = useDispatch();

    const handlesubmit = async (e) => {
        e.preventDefault();
        let numbers = 0;
        let errorData = [];
        var date = new Date();
        date.setDate(date.getDate() + 7);
      
        for (let i in data) {
            let myrow = data[i]
            numbers = i;
            const formData = {
                productname: myrow.productname,
                slugUrl: myrow.slugUrl,
                metaTitle: myrow.title,
                metaKeyword: myrow.keyword,
                metaDesc: myrow.discreption,
                categoryId: categoryid,
                category: category,
                color: myrow.color,
                type: myrow.type,
                material: myrow.material,
                theme: myrow.theme,
                suitablefor: myrow.suitablefor,
                trending: myrow.trending,
                offers: myrow.offers,
                recommends: myrow.recommends,
                status: myrow.status,
                rating: myrow.rating,
                brand: myrow.brand,
                dimensions: myrow.dimensions,
                weight: myrow.weight,
                stock: myrow.stock,
                stocktodispatch: myrow.stocktodispatch,
                discount: myrow.discount,
                mrp: myrow.mrp,
                price: myrow.price,
                minimumquantity: myrow.minimumquantity,
                productsizelabel: myrow.productsizelabel,
                mblimg: `https://decoratin.com.au/productImg/mblimg/${myrow.mblimg}`,
                dskimg: `https://decoratin.com.au/productImg/dskimg/${myrow.dskimg}`,
                slider: `https://decoratin.com.au/productImg/slider/${myrow.slider}`,
                productsizecode: myrow.productsizecode,
                about: myrow.details,
                productInfo: myrow.details,
            }
            const postProduct = await dispatch(productPost(formData));

            // const config = {
            //     Headers: { "content-type": "application/json" },
            // };
            // const url = `http://localhost:4022/api/v1/product/new`;
            // const resp = await axios.post(url, formData, config);


            // if (resp.data.success === true) {
            //     setUploadedProductName(`${Number(numbers) + 1}) ${myrow.productname} upload successfull`)
            // } else {
            // }
        }
    }

    const handlefile = async (e) => {
        const file = e.target.files[0];
        const f = await file.arrayBuffer();
        const wb = read(f);
        const worksheetname = wb.SheetNames[0];
        const worksheet = wb.Sheets[worksheetname];
        const datas = utils.sheet_to_json(worksheet);
        setData(datas);
    };

    const selectCategory = (evt) => {
        const { value } = evt.target;
        const index = evt.nativeEvent.target.selectedIndex;
        const text = evt.nativeEvent.target[index].text;
        setCategoryid(value);
        setCategory(text);
    };


    return (
        <div className="content-wrapper">
            <div className="employeesContainer">
                <form onSubmit={handlesubmit}>
                    <div className="card m-4">
                        <div className="card-footer">
                            <h5>Product Bulk Upload</h5>
                        </div>
                        <div className="card-body">
                            <Row>
                                <Col md>
                                    <Form.Label htmlFor="disabledTextInput">
                                        Category Name
                                    </Form.Label>

                                    <Form.Select
                                        aria-label="Floating label select example"
                                        onChange={(e) => {
                                            selectCategory(e);
                                        }}
                                        value={categorytotal._id}
                                        name={categorytotal.name}
                                    >
                                        <option value="0">{"Select Category"}</option>
                                        {categorytotal.map((data) => (
                                            <option
                                                key={data._id}
                                                value={data._id}
                                                name={data.catname}
                                                required
                                            >
                                                {data.catname}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="g-2  m-2">
                                <Col md>
                                    <Form.Label htmlFor="disabledTextInput">
                                        Upload Excel File *
                                    </Form.Label>

                                    <Form.Control
                                        type="file"
                                        placeholder="enter name....."
                                        onChange={(e) => handlefile(e)}
                                    />
                                </Col>
                                <Col md>
                                    <button
                                        className="btn btn-primary me-2 mt-4 ml-5 "
                                        type="submit"
                                    >
                                        Product Bulk Upload
                                    </button>
                                </Col>
                            </Row>

                            <p style={{ color: "green" }} className="mt-2">
                                {data.length}
                            </p>
                            <p style={{ color: "green" }} className="mt-2">
                                {uploadedProductName}
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}

export default BulkProductUpload