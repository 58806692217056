import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

export const adminRegister = createAsyncThunk(
  "admin/adminRegister",
  async (formData, thunkAPI) => {
    let resp = {
      success: false,
      message: "user not registered",
    };
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/login`;
      resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const adminUpdate = createAsyncThunk(
  "admin/adminUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/admin/adminupdate/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("agent Not create");
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuth: JSON.parse(localStorage.getItem("loginData"))?.isAuth
      ? JSON.parse(localStorage.getItem("loginData")).isAuth
      : false,
    token: JSON.parse(localStorage.getItem("loginData"))?.token
      ? JSON.parse(localStorage.getItem("loginData")).token
      : "",
    name: JSON.parse(localStorage.getItem("loginData"))?.name
      ? JSON.parse(localStorage.getItem("loginData")).name
      : "",
    email: JSON.parse(localStorage.getItem("loginData"))?.email
      ? JSON.parse(localStorage.getItem("loginData")).email
      : "",
    clientid: JSON.parse(localStorage.getItem("loginData"))?.clientid
      ? JSON.parse(localStorage.getItem("loginData")).clientid
      : "",
    loginData: localStorage.getItem("loginData")
      ? JSON.parse(localStorage.getItem("loginData"))
      : "",

    officerName: "",
    officerMobile: "",
    isAuthLoading: true,
  },
  reducers: {
    signin(state, action) {
      state.loginData = action.payload;
      state.officerName = action.payload.officersName;
      state.officerMobile = action.payload.officerMobile;
      localStorage.setItem("loginData", JSON.stringify(state.loginData));
      state.isAuth = true;
    },
    signout(state, action) {
      localStorage.removeItem("loginData");
      // localStorage.clear();
      state.isAuth = false;
    },
  },
  extraReducers: (builder)=>{
    builder
    .addCase(adminUpdate.pending, (state) => {
      
      state.isAuthLoading = true;
    })
    .addCase(adminUpdate.fulfilled, (state,action) => {
      if (action.payload.success) {
        state.loginData = action.payload.adminss;
        let adminDetails = state.loginData;
        state.loginData.officersName = adminDetails.officersName;
        state.loginData.officerMobile = adminDetails.officerMobile;
  
        let logindata = {
          ...adminDetails,
          isAuth: true,
        };
        state.loginData = logindata;
        localStorage.setItem("loginData", JSON.stringify(state.loginData));
      }
      state.isAuthLoading = false;
    })
    .addCase(adminUpdate.rejected, (state) => {
      
      state.isAuthLoading = true;
    })
    
  }
});

export const authActions = authSlice.actions;
export default authSlice;
