import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl, } from "../../config/BaseUrl";

const initialState = {
  producttotal: [],
  producticon: "",
  productbanner: [],
  productdesktop: "",
  productthumb: "",
  isproductIconLoading: true,
  isproductthumbLoading: true,
  isproductbanLoading: true,
  isproductdeskLoading: true,
  isLoading: true,
  productLoading: true,
  productpacksizeLoading: true,
  delproductLoading: true,
};

export const getproduct = createAsyncThunk(
  "product/getproduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/all`;
      const resp = await axios(url);
      return resp.data.Products;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);


export const productPost=createAsyncThunk(
  "product/productPost",
  async(formData,thunkAPI)=>{
    try{
      const config={
        Headers:{"content-type":"application/json"},
      };
      const url=`${Baseurl}/api/v1/product/new`;
      const resp=await axios.post(url,formData,config);
      return resp.data;
    }catch(error){
        return thunkAPI.rejectWithValue("Product not create")
    }
  }
)
export const productUpdate = createAsyncThunk(
  "product/productUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/product/update/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const productpacksizeUpdate = createAsyncThunk(
  "product/productUpdatepacksize",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };
      const url = `${Baseurl}/api/v1/product/packsize/${formData._id}`;
      const resp = await axios.put(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const productDel = createAsyncThunk(
  "product/productDel",
  async (id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/product/${id}`;
      const resp = await axios.delete(url);
      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Not create");
    }
  }
);

export const productThumbnail = createAsyncThunk(
  "product/productThumbnail",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
        maxBodyLength: Infinity,
      };

      const url = `${Baseurl}/api/v1/product/thumbnail`;
      const resp = await axios.post(url, formData, config);

      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("product Thumbnail Not create");
    }
  }
);

export const validateProductSlugUrl = createAsyncThunk(
  "product/validateproductSlugUrl",
  async (slugurl, thunkAPI) => {
    let resp = {
      success: false,
      message: "new slugurl",
    };
    try {
      const url = `${Baseurl}/api/v1/product/slugurl/${slugurl}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return error;
    }
  }
);

const ProductSlice = createSlice({
  name: "productAll",
  initialState,
  reducers: {
    updateproductThumbnail(state, action) {
      state.productthumb = action.payload;
      state.isproductthumbLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getproduct.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getproduct.fulfilled, (state,action) => {
      state.producttotal = action.payload;
  
      state.isLoading = false;
      state.productLoading = false;
    })
    .addCase(getproduct.rejected, (state,action) => {
      state.isLoading = true;
    })
    .addCase(productPost.pending, (state) => {
      state.productLoading = true;
    })
    .addCase(productPost.fulfilled, (state,action) => {
      if (action.payload.success) {
        state.producttotal = [...state.producttotal, action.payload.product];
        state.producticon = "";
        state.productbanner = [];
        state.productdesktop = "";
        state.productthumb = "";
        state.isproductbanLoading = true;
        state.isproductdeskLoading = true;
        state.isproductIconLoading = true;
        state.isproductthumbLoading = true;
  
      }
      state.productLoading = false;
    })
    .addCase(productPost.rejected, (state,action) => {
      state.productLoading = true;
    })
    .addCase(productUpdate.pending, (state) => {
      state.productLoading = true;
    })
    .addCase(productUpdate.fulfilled, (state,action) => {
      if (action.payload.success) {
        state.producttotal = state.producttotal.filter(
          (product) => product._id !== action.payload.product._id
        );
        state.producttotal = [...state.producttotal, action.payload.product];
        state.producticon = "";
        state.productbanner = [];
        state.productdesktop = "";
        state.productthumb = "";
        state.isproductIconLoading = true;
        state.isproductthumbLoading = true;
        state.isproductbanLoading = true;
        state.isproductdeskLoading = true;
        state.isLoading = !state.isLoading;
  
      }
      state.productLoading = false;
    })
    .addCase(productUpdate.rejected, (state,action) => {
      state.productLoading = true;
    })
    .addCase(productDel.pending, (state) => {
      state.delgroceyLoading = true;
    })
    .addCase(productDel.fulfilled, (state,action) => {   
      if (action.payload.success) {
        state.producttotal = state.producttotal.filter(
          (product) => product._id !== action.payload.id
        );
        state.producticon = "";
        state.productbanner = [];
        state.productdesktop = "";
        state.productthumb = "";
        state.isproductbanLoading = true;
        state.isproductdeskLoading = true;
        state.isproductIconLoading = true;
        state.isproductthumbLoading = true;
  
      }
      state.delgroceyLoading = false;
    })
    .addCase(productDel.rejected, (state,action) => {
      state.delgroceyLoading = true;
    })
    .addCase(productThumbnail.pending, (state) => {
      state.isproductthumbLoading = true;
    })
    .addCase(productThumbnail.fulfilled, (state,action) => {
      if (action.payload.success) {
        state.productthumb = action.payload.thumbnails;
      }
      state.isproductthumbLoading = false;
    })
    .addCase(productThumbnail.rejected, (state,action) => {
      state.isproductthumbLoading = true;
    })
    .addCase(productpacksizeUpdate.pending, (state) => {
      state.productpacksizeLoading = true;
    })
    .addCase(productpacksizeUpdate.fulfilled, (state,action) => {
      if (action.payload.success) {
        state.producttotal = state.producttotal.filter(
          (productpacksize) =>
            productpacksize._id !== action.payload.productpacksize._id
        );
        state.producttotal = [...state.producttotal, action.payload.product];
        state.producticon = "";
        state.productbanner = [];
        state.productdesktop = "";
        state.productthumb = "";
        state.isproductIconLoading = true;
        state.isproductthumbLoading = true;
        state.isproductbanLoading = true;
        state.isproductdeskLoading = true;
      }
      state.productpacksizeLoading = false;
    })
    .addCase(productpacksizeUpdate.rejected, (state,action) => {
      state.productpacksizeLoading = true;
    })

  },
});
export const {
  updateproductIcon,
  updateproductThumbnail,
  updateproductBanner,
  updateproductDesktop,
  resetImage,
} = ProductSlice.actions;
export default ProductSlice.reducer;
