import React from "react";
import { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryThumbnail,
  validateSlugUrl,
  categoryPost,
} from "../../../redux/category/categorySlice";
import { imageListClasses } from "@mui/material";
import { Await } from "react-router-dom";

function AddCategory() {
  const dispatch = useDispatch();
  const { isCatthumbLoading, catthumb } = useSelector(
    (store) => store.categoryAll
  );
  const [category, setCategory] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [checkSlugUrl, setCheckSlugUrl] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDesc, setMetaDesc] = useState("");

  const verifySlugUrl = async (e) => {
    const value = e.target.value;
    setSlugUrl(value);
    setCategory(value);
    setError("");
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("----", "-")
      .replace("---", "-")
      .replace("--", "-");

    setSlugUrl(slug);

    if (slug !== "") {
      let response = await dispatch(validateSlugUrl(slug));

      if (response.payload.success) {
        setError("Category already exist");
        alert("Category already exist");
        setCheckSlugUrl("");
      } else {
        setCheckSlugUrl(slug);
        setError("");
      }
    }
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryThumbnail({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category !== "" && !isCatthumbLoading) {
      const formData = {
        catname:
          category.charAt(0).toUpperCase() + category.slice(1).toLowerCase(),
        mblimg: catthumb,
        slugUrl: checkSlugUrl,
        metaTitle: metaTitle,
        metaKeyword: metaKeyword,
        metaDesc: metaDesc,
      };
      const data = await dispatch(categoryPost(formData));
      if (!data) {
        alert("Please check the fields");
      } else if (data.payload.success) {
        alert("Category Added");
        setCategory("");
        setSlugUrl("");
        setMetaTitle("");
        setMetaKeyword("");
        setMetaDesc("");
      }
    } else if (category === 0) {
      alert("please enter category name");
    } else if (isCatthumbLoading) {
      alert("please select Image");
    } else {
      alert("Please check the data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handleSubmit}>
              <h4 className="p-4">Add Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        required
                        value={category}
                        onChange={(e) => {
                          verifySlugUrl(e);
                        }}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metaTitle}
                        onChange={(e) => {
                          setMetaTitle(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metaKeyword}
                        onChange={(e) => {
                          setMetaKeyword(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metaDesc}
                        onChange={(e) => {
                          setMetaDesc(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Category Website Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 360px * 270px
                      </p>
                      <div>
                        {isCatthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={catthumb} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {category !== "" &&
                  !isCatthumbLoading &&
                  !error.length > 0 ? (
                    <button className="btn btn-primary" type="submit">
                      Add Category
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Category
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
}

export default AddCategory;
