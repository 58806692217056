import React, { Fragment, useEffect, useState } from 'react'
import { Baseurl } from '../../../config/BaseUrl'
import { orderDetails } from "../../../redux/productOrder/productOrderSlice";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';



const DetailOrder = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [singleOrderDetail, setSingleOrderDetail] = useState("")
    const orderDetail = useSelector((store) => store.productOrder)

    const fetchOrder = async () => {
        const orderDetail = await dispatch(orderDetails(params._id));
        if (orderDetail.payload.success) {
            setSingleOrderDetail(orderDetail.payload.productOrder)
        }
    }
    useEffect(() => {
        fetchOrder()
    }, [])
    return (
        <Fragment>
            <div className="content-wrapper"> 
                {
                    singleOrderDetail ? (<>
                        <div>
                            <Table
                                hover
                                responsive
                            >
                                <thead>
                                    <tr>
                                        <th>
                                            No.
                                        </th>
                                        <th>
                                            Preview
                                        </th>
                                        <th>
                                            Product
                                        </th>
                                        <th>
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        singleOrderDetail && singleOrderDetail.orderproducts.map((individualOrder, index) =>
                                            <tr key={index}>
                                                <th scope="row">
                                                    3
                                                </th>
                                                <td>
                                                    <img src={individualOrder.mblimg} alt="" height='80px' width='80px' />
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='order-title'>
                                                            Name: <span className='order-info'>{individualOrder.name}</span>
                                                        </div>
                                                        <div className='order-title'>
                                                            Brand: <span className='order-info'>{individualOrder.brand}</span>
                                                        </div>
                                                        <div className='order-title'>
                                                            Category: <span className='order-info'>{individualOrder.category}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <div className='order-title'>
                                                            Price: <span className='order-info'>{individualOrder.packsize.price}</span>
                                                        </div>
                                                        <div className='order-title'>
                                                            Packsize: <span className='order-info'>{individualOrder.packsize.packName}</span>
                                                        </div>
                                                        <div className='order-title'>
                                                            Qty: <span className='order-info'>{individualOrder.Qty}</span>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>
                        </div>
                    </>) : (<></>)
                }
            </div>
        </Fragment>
    )
}

export default DetailOrder