import "./App.css";

import axios from "axios";
import AddCategory from "./components/pageSetting/category/AddCategory";
import { getCategory } from "./redux/category/categorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import AdminRegister from "./components/pageSetting/AdminRegister";
import { getBrand } from "./redux/brand/brandSlice";
import AddBrand from "./components/pageSetting/brand/Brand";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loginpage from "./components/loginPage/LoginPage";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./components/Dashboard";
import { Product } from "./components/layout/Product";
import BulkCategoryUploade from "./components/pageSetting/category/BulkCategoryUpload";
import BulkBrandUpload from "./components/pageSetting/brand/BulkBrandUpload";
import ListCategory from "./components/pageSetting/category/ListCategory";
import UpdateBrand from "./components/pageSetting/brand/UpdateBrand";
import ListBrand from "./components/pageSetting/brand/ListBrand";
import UpdateCategory from "./components/pageSetting/category/UpdateCategory";
import AddProduct from "./components/pageSetting/products/AddProduct";
import BulkProductUpload from "./components/pageSetting/products/BulkProductUpload";
import { getproduct } from "./redux/product/productSlice";
import { ListProduct } from "./components/pageSetting/products/ListProduct";
import CourierPrices from "./components/pageSetting/courier-prices/CourierPrices";
import { getCourierPrices } from "./redux/courierRate/courierSlice";
import UpdateProducts from "./components/pageSetting/products/UpdateProducts";
import { getCourierList, getEcomCourierList } from "./redux/addCourier/addCourierSlice";
import AddCourier from "./components/pageSetting/add-courier/AddCourier";
import EditCourier from "./components/pageSetting/add-courier/EditCourier";
import ListCourier from "./components/pageSetting/add-courier/ListCourier";
import { getOrders } from "./redux/productOrder/productOrderSlice";
import ListOrders from "./components/pageSetting/orders/ListOrders";
import ProcessOrders from "./components/pageSetting/orders/ProcessOrders";
import DispatchOrders from "./components/pageSetting/orders/DispatchOrders";
import { getCourierRequest } from "./redux/courierRequest/courierRequestSlice";
import EnquiryList from "./components/pageSetting/courierEnquiry/EnquiryList";
import ViewedEnquiry from "./components/pageSetting/courierEnquiry/ViewedEnquiry";
import RemovedEnquiry from "./components/pageSetting/courierEnquiry/RemovedEnquiry";
import EcomCourier from "./components/pageSetting/orders/EcomCourier";
import CourierPriceList from "./components/pageSetting/courier-prices/CourierPriceList";
import EditCourierprice from "./components/pageSetting/courier-prices/EditCourierprice";
import DetailOrder from "./components/pageSetting/orders/DetailOrder";
import CancelOrder from "./components/pageSetting/orders/CancelOrder";
import DeliveredOrder from "./components/pageSetting/orders/DeliveredOrder";

function App() {
  const { courierPrices } = useSelector((store) => store.courierPrices);
  const { courierList } = useSelector((store) => store.courierList);
  const { orderList } = useSelector((store) => store.productOrder);
  const dispatch = useDispatch();
  useEffect(() => {
    const initialSetup = async () => {
      try {
        //Category
        dispatch(getCategory());
        //getBrand
        dispatch(getBrand());
        //getProduct
        dispatch(getproduct());
        //getCouierPrice
        dispatch(getCourierPrices());
        //getCourierList
        dispatch(getCourierList());
        //getOrderList
        dispatch(getOrders())
        //getCourierRequest
        dispatch(getCourierRequest())
        dispatch(getEcomCourierList())
      } catch (error) {}
    };
    initialSetup();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/admin/addcategory" element={<AddCategory />} />
            <Route
              path="/admin/bulk-category-uploade"
              element={<BulkCategoryUploade />}
            />
            <Route path="/admin/listcategory" element={<ListCategory />} />
            <Route
              path="/admin/updatecategory/:slugurl"
              element={<UpdateCategory />}
            />
            <Route path="/admin/addbrand" element={<AddBrand />} />
            <Route path="/admin/listbrand" element={<ListBrand />} />
            <Route
              path="/admin/updatebrand/:slugurl"
              element={<UpdateBrand />}
            />
            <Route path="/admin/add-products" element={<AddProduct />} />
            <Route path="/admin/listproduct" element={<ListProduct />} />
            <Route
              path="/admin/updateproduct/:slugurl"
              element={<UpdateProducts />}
            />
            <Route
              path="/admin/product-bulkupload"
              element={<BulkProductUpload />}
            />
            <Route
              path="/admin/brand-bulkupload"
              element={<BulkBrandUpload />}
            />
            <Route path="/admin/register" element={<AdminRegister />} />
            <Route path="/admin/listorders" element={<ListOrders />} />
            <Route path="/admin/orderdetail/:_id" element={<DetailOrder />} />
            <Route path="/admin/ecomcourier/:awb" element={<EcomCourier />} />
            <Route path="/admin/processorders" element={<ProcessOrders />} />
            <Route path="/admin/dispatchorders" element={<DispatchOrders />} />
            <Route path="/admin/deliveredorders" element={<DeliveredOrder />} />
            <Route path="/admin/cancelorder" element={<CancelOrder />} />
            <Route
              path="/admin/courierprices-upload"
              element={<CourierPrices />}
            />
            <Route path="/admin/courierpricelist" element={<CourierPriceList />} />
            <Route path="/admin/editcourierprice/:_id" element={<EditCourierprice />} />
            <Route path="/admin/courier" element={<AddCourier />} />
            <Route path="/admin/listcourier/" element={<ListCourier/>} />
            <Route path="/admin/edit-courier/:awb" element={<EditCourier/>} />

            <Route path="/admin/listenquiry" element={<EnquiryList/>} />
            <Route path="/admin/viewedenquiry" element={<ViewedEnquiry/>} />
            <Route path="/admin/removedenquiry" element={<RemovedEnquiry/>} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <AddCategory/>
      <AddSubCategory/>
      <AddBrand/>
      <AdminRegister/> */}
    </div>
  );
}

export default App;
