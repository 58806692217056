import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  courierPrices: [],
  courierPriceLoading: false,
  isLoading: false,
};

export const getCourierPrices = createAsyncThunk(
  "courier/getcourier",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/courierprice/all`;
      const resp = await axios.get(url);
      return resp.data.courierPrices;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 not found");
    }
  }
);

export const courierPricePost = createAsyncThunk(
  "courier/courierPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "content-type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/courierprice/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data.courierPrice;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier Price not create");
    }
  }
);

export const courierPriceUpdate = createAsyncThunk(
  "courier/courierUpdate",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/courierprice/updateby/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier Price not updated");
    }
  }
);

const CourierPriceSlice = createSlice({
  name: "courierPriceAll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCourierPrices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCourierPrices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courierPrices = action.payload;
      })
      .addCase(getCourierPrices.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(courierPricePost.pending, (state) => {
        state.courierPriceLoading = true;
      })
      .addCase(courierPricePost.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.courierPrices = [
            ...state.courierPrices,
            action.payload.courierPrices,
          ];
          state.isLoading = false;
        }
        state.courierPriceLoading = false;
      })
      .addCase(courierPricePost.rejected, (state) => {
        state.courierPriceLoading = true;
      })
      .addCase(courierPriceUpdate.pending, (state) => {
        state.courierPriceLoading = true;
      })
      .addCase(courierPriceUpdate.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.courierPrices = state.courierPrices.filter(
            (singleRequest) =>
              singleRequest._id !== action.payload.courierPrice._id
          );
          state.courierPrices = [
            ...state.courierPrices,
            action.payload.courierPrice,
          ];
          state.isLoading = false;
        }
        state.courierPriceLoading = false;
      })
      .addCase(courierPriceUpdate.rejected, (state) => {
        state.courierPriceLoading = true;
      });
  },
});

export default CourierPriceSlice.reducer;
