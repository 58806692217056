import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  categoryThumbnail,
  categoryIcon,
  categoryPost,
  categoryBanner,
  categoryDesktop,
  validateSlugUrl,
  categoryimgnew,
  updateCatThumbnail,
  updateCatBanner,
  categoryUpdate,
  updateCatIcon,
  updateCatDesktop,
} from "../../../redux/category/categorySlice";

const UpdateCategory = () => {
  const { isAuth } = useSelector((store) => store.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    }
  }, []);
  const {
    catthumb,
    isCatthumbLoading,
    categorytotal,
  } = useSelector((store) => store.categoryAll);

  const [categroy, setCategroy] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [priority, setPriority] = useState("");
  const [catId, setCatId] = useState("");

  const dispatch = useDispatch();

  const params = useParams();

  const verifyslugurl = async (e) => {
    const value = e.target.value;
    setSlugUrl(value);
    setCategroy(value);
    setError("");
    let slug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
    if (slug != "") {
      let responce = await dispatch(validateSlugUrl(slug));

      if (responce.payload.success) {
        setError("SlugUrl already exist");
        alert("category alredy exist");
      } else {
        setCheckSlugUrl(slug);
        setError("");
      }
    }
  };
  useEffect(() => {
    const catUrl = params.slugurl;
    const updatecat = categorytotal.find((cat) => cat.slugUrl === catUrl);

    setCatId(updatecat._id);
    setCategroy(updatecat.catname);
    setSlugUrl(updatecat.slugUrl);
    setMetatitle(updatecat.metaTitle);
    setMetakeyword(updatecat.metaKeyword);
    setMetadesc(updatecat.metaDesc);
    setPriority(updatecat.priority);
    dispatch(updateCatThumbnail(updatecat.mblimg));
  }, [params.slugurl]);

  const categorychange = (e) => {
    const name = e.target.value;
    setCategroy(name);
    let slug = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slug);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (
      categroy !== ""
       && !isCatthumbLoading
    ) {
      const formData = {
        catname:
          categroy.charAt(0).toUpperCase() + categroy.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        thumbnail: catthumb,
        // icon: caticon,
        // desktopicon: catdesktop,
        // banner: catbanner,
        priority: priority,
        catid: catId,
      };
      const data = await dispatch(categoryUpdate(formData));
      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("Category Updated");
        } else {
          alert(data.message);
        }
      }
    }
  };
  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(categoryThumbnail({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Update Category</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Update Category</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Enter Category"
                        required
                        value={categroy}
                        onChange={(e) => verifyslugurl(e)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Priority
                      </Form.Label>

                      <Form.Control
                        type="number"
                        placeholder="Enter priority"
                        required
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 w-50"></Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => setMetatitle(e.target.value)}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => setMetakeyword(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => setMetadesc(e.target.value)}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Category Website Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 360px * 270px
                      </p>
                      <div>
                        {isCatthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={catthumb} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {
                    !isCatthumbLoading  &&
                    categroy !== "" ? (
                      <button className="btn btn-primary" type="submit">
                        Update Category
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary"
                        disabled
                        type="submit"
                      >
                        Update Category
                      </button>
                    )
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default UpdateCategory;
