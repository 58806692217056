import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  courierRequest: [],
  processingRequest:[],
  deletedRequest:[],
  isLoading: true,
};

export const courierRequestPost = createAsyncThunk(
  "courier/courierpost",
  async (formdata, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/requestcourier/new`;
      const resp = await axios.post(url, formdata, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier request not created");
    }
  }
);

export const getCourierRequest = createAsyncThunk(
  "courierrequest/courierrequestlist",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/requestcourier/all`;
      const resp = await axios.get(url);
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue("Requset Courier list not found");
    }
  }
);

export const updateCourierRequest=createAsyncThunk(
  "courierrequest/updaterequest",
  async(formData,thunkAPI)=>{
    try{
      const config={
        Headers:{
          "content-type":"application/json"
        }
      }
      const url=`${Baseurl}/api/v1/requestcourier/updateby/${formData.id}`;
      const resp=await axios.put(url,formData,config);
      return resp.data
    }catch(error){
      return thunkAPI.rejectWithValue("Courier request not updated")
    }
  }
)

export const deleteCourierRequest = createAsyncThunk(
  "courierrequest/deletecourierrequest",
  async (formData, thunkAPI) => {
    try {
      const url=`${Baseurl}/api/v1/requestcourier/delete/${formData.id}`
      const resp=await axios.delete(url);
      return resp.data
    } catch (error) {
      return thunkAPI.rejectWithValue("Courier request not able to delete");
    }
  }
);



const RequestCourierSlice=createSlice({
    name:"CourierRequest",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder
        .addCase(courierRequestPost.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(courierRequestPost.fulfilled,(state,action)=>{
            state.courierRequest=[action.payload.courierRequest,...state.courierRequest]
            state.isLoading=false
        })
        .addCase(courierRequestPost.rejected,(state)=>{
            state.isLoading=true
        })
        .addCase(getCourierRequest.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(getCourierRequest.fulfilled,(state,action)=>{
          state.courierRequest=action.payload.courierRequests.filter((enquiry)=>
            enquiry.status===1
          )
          state.processingRequest=action.payload.courierRequests.filter((enquiry)=>
            enquiry.status===2
          )
          state.deletedRequest=action.payload.courierRequests.filter((enquiry)=>
            enquiry.status===0
          )
          state.isLoading=false
        })
        .addCase(getCourierRequest.rejected,(state)=>{
            state.isLoading=true
        })
        .addCase(updateCourierRequest.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(updateCourierRequest.fulfilled,(state,action)=>{
          if(action.payload.success){
            state.courierRequest=state.courierRequest.filter((singleRequest)=>
              singleRequest._id!==action.payload.requestCourier._id
            )
          }
          if(action.payload.requestCourier.status===1){
            state.courierRequest =[action.payload.requestCourier,...state.courierRequest]  
          }
          if(action.payload.requestCourier.status===2){
            state.processingRequest =[action.payload.requestCourier,...state.processingRequest]
          }
          if(action.payload.requestCourier.status===0){
            state.deletedRequest =[action.payload.requestCourier,...state.deletedRequest]  
          }
          state.isLoading=false
        })
        .addCase(updateCourierRequest.rejected,(state)=>{
            state.isLoading=true
        })
        .addCase(deleteCourierRequest.pending,(state)=>{
            state.isLoading=true
        })
        .addCase(deleteCourierRequest.fulfilled,(state,action)=>{
            state.courierRequest=state.courierRequest.filter((singleRequest)=>
                singleRequest._id!==action.payload._id
            )
            state.isLoading=false
        })
        .addCase(deleteCourierRequest.rejected,(state)=>{
            state.isLoading=true
        })
    }
})

export default RequestCourierSlice.reducer;