//AddProduct
import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { Button } from "react-bootstrap";
import {
  productThumbnail,
  productPost,
  validateProductSlugUrl,
} from "../../../redux/product/productSlice";

function AddProduct() {
  const { categorytotal } = useSelector((store) => store.categoryAll);
  const { brandtotal } = useSelector((store) => store.brandAll);
  const weights = [{ name: "250gm" }, { name: "500gm" }, { name: "1Kg" }];
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [error, setError] = useState(false);
  const [product, setproduct] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [errorslug, setErrorslug] = useState("");
  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [shortName, setShortName] = useState("");
  const [about, setAbout] = useState("");
  const [productInfo, setProductInfo] = useState("");
  const [manufacturerDetails, setManufacturerDetails] = useState("");
  const [ingredients, setIngredients] = useState("");

  const [hotproducts, setHotproducts] = useState(Boolean(0));
  const [trending, setTrending] = useState(Boolean(0));
  const [offers, setOffers] = useState(Boolean(0));
  const [recommended, setRecommended] = useState(Boolean(0));
  const [productoutofstock, setProductoutofstock] = useState(Boolean(0));
  const [metatitle, setMetatitle] = useState("");
  const [metakeyword, setMetakeyword] = useState("");
  const [metadesc, setMetadesc] = useState("");

  const { productthumb, isproductthumbLoading } = useSelector(
    (store) => store.productAll
  );

  const [options, setOptions] = useState([
    {
      packName: "",
      price: "",
      prime: true,
    },
  ]);

  const dispatch = useDispatch();
  
  function selectCategory(evt) {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setCategoryid(value);
    setCategory(text);
  }

  function selectBrand(evt) {
    const { value } = evt.target;
    const index = evt.nativeEvent.target.selectedIndex;
    const text = evt.nativeEvent.target[index].text;
    setBrand(text);
  }

  const productchange = async (e) => {
    const name = e.target.value;
    setproduct(name);
    setSlugUrl(name);
    setErrorslug("");

    let slugUrlOrg = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^'!/{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");

    setSlugUrl(slugUrlOrg);
    if (slugUrlOrg !== "") {
      let response = await dispatch(validateProductSlugUrl(slugUrlOrg));
      if (response.payload.success) {
        setErrorslug("Product already exist");
      } else {
        setCheckSlugUrl(slugUrlOrg);
        setErrorslug("");
      }
    }
  };

  const thumbnailChange = async (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(productThumbnail({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const onPrimeChange = (index) => {
    let optionList = [];
    optionList = [...options];
    const findOnePrime = optionList.find((item) => item.prime === true);
    if (optionList.length > 1 && findOnePrime) {
      optionList = optionList.map((item, itemindex) => {
        if (itemindex === index) {
          item.prime = true;
        } else {
          item.prime = false;
        }
        return item;
      });
    }
    setOptions(optionList);
  };

  const handleCheck = (index, e, selected) => {
    let temp = [...options];
    temp[index][selected] = e.target.value;
    if (selected === "price") {
      temp[index][selected] = Number(e.target.value.replace(/\D/g, ""));
    }
    onPrimeChange(index);
    setOptions(temp);
  };

  const removeRowClick = (e, index) => {
    let optionList = [];
    optionList = [...options];
    optionList = optionList.filter((item, itemindex) => itemindex !== index);
    setOptions(optionList);
  };

  const handleNewRow = () => {
    setOptions([
      ...options,
      {
        packName: "",
        price: 0,
        prime: false,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (product !== "" && brand !== "" && !isproductthumbLoading) {
      const formData = {
        name: product.charAt(0).toUpperCase() + product.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        metaTitle: metatitle,
        metaKeyword: metakeyword,
        metaDesc: metadesc,
        category: category,
        categoryId: categoryid,
        brand: brand,
        ingredient: ingredients,
        trending: trending,
        offers: offers,
        recommends: recommended,
        productOutOfStock: productoutofstock,
        mblimg: productthumb,
        productInfo: productInfo,
        manufacturer: manufacturerDetails,
        packsize: options,
      };
      const data = await dispatch(productPost(formData));
      if (!data) {
        alert("Please check the fields");
      } else {
        if (data.payload.success) {
          alert("Product Added");
          setproduct("");
          setSlugUrl("");
          setMetatitle("");
          setMetakeyword("");
          setMetadesc("");
          setCategory("");
          setCategoryid("");
          setBrand("");
          setShortName("");
          setAbout("");
          setIngredients("");
          setTrending(Boolean(0));
          setOffers(Boolean(0));
          setRecommended(Boolean(0));
          setProductoutofstock(Boolean(0));
          setProductInfo("");
          setManufacturerDetails("");
          setOptions([
            {
              packName: "",
              price: "",
              prime: true,
            },
          ]);
        } else {
          alert(data.message);
        }
      }
    } else if (options.length === 0) {
      alert("Please add options correctly");
    } else if (brand === 0) {
      alert("Please select Brand");
    } else if (product === "") {
      alert("please enter unique product name");
    } else {
      alert("please check the data");
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handleSubmit}>
              <h4 className="p-4">Add Product</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Category <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectCategory(e);
                        }}
                        value={categorytotal._id}
                        name={categorytotal.catname}
                      >
                        <option value="0" >{"Select Category"}</option>
                        {categorytotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.catname}
                            required
                          >
                            {data.catname}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="errorhandle mt-2">
                        {error && categorytotal.length <= 0 ? (
                          <label className="errorlabel">
                            Select Category *
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Brand <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Select
                        aria-label="Floating label select example"
                        onChange={(e) => {
                          selectBrand(e);
                        }}
                        name={brandtotal.name}
                      >
                        <option value="0" >{"Select Brand"}</option>
                        {brandtotal.map((data) => (
                          <option
                            key={data._id}
                            value={data._id}
                            name={data.name}
                          >
                            {data.name}
                          </option>
                        ))}
                      </Form.Select>
                      <div className="errorhandle mt-2">
                        {error && brandtotal.length <= 0 ? (
                          <label className="errorlabel">
                            Select Brand <span style={{ color: "red" }}>*</span>
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Name <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name....."
                        required
                        value={product}
                        onChange={(e) => productchange(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {errorslug}
                      </p>

                      <div className="errorhandle mt-2">
                        {error && product.length <= 0 ? (
                          <label className="errorlabel">
                            please input product name *
                          </label>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Product Info
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter product info"
                        style={{ height: "100px" }}
                        value={productInfo}
                        onChange={(e) => setProductInfo(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2 ">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Manufacturer Details
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter  Manufacturer Details"
                        required
                        value={manufacturerDetails}
                        onChange={(e) => setManufacturerDetails(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Ingredients
                        <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Ingredients"
                        required
                        value={ingredients}
                        onChange={(e) => setIngredients(e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2"></Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Trending</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="Trending"
                            type="checkbox"
                            value="Trending"
                            checked={trending === true}
                            onChange={(e) => {
                              setTrending(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="Trending"></label>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Recommends</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="recommended"
                            type="checkbox"
                            value="recommended"
                            checked={recommended === true}
                            onChange={(e) => {
                              setRecommended(e.target.checked);
                            }}
                          />
                          <label
                            className="tgl-btn"
                            htmlFor="recommended"
                          ></label>
                        </div>
                      </div>
                    </Col>

                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">Offers</Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="offers"
                            type="checkbox"
                            value="offers"
                            checked={offers === true}
                            onChange={(e) => {
                              setOffers(e.target.checked);
                            }}
                          />
                          <label className="tgl-btn" htmlFor="offers"></label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <div class="checkbox-wrapper-6 d-flex">
                        <Form.Label className="mx-2">
                          Product Out Of Stock
                        </Form.Label>
                        <div>
                          <input
                            className="tgl tgl-light"
                            id="productoutofstock"
                            type="checkbox"
                            value="productoutofstock"
                            checked={productoutofstock === true}
                            onChange={(e) => {
                              setProductoutofstock(e.target.checked);
                            }}
                          />
                          <label
                            className="tgl-btn"
                            htmlFor="productoutofstock"
                          ></label>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Title </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Title......"
                        style={{ height: "100px" }}
                        value={metatitle}
                        onChange={(e) => {
                          setMetatitle(e.target.value);
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label>Meta Keyword </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Keyword"
                        style={{ height: "100px" }}
                        value={metakeyword}
                        onChange={(e) => {
                          setMetakeyword(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col>
                      <Form.Label>Meta Descriptrion </Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Meta Descriptrion......"
                        style={{ height: "100px" }}
                        value={metadesc}
                        onChange={(e) => {
                          setMetadesc(e.target.value);
                        }}
                      />
                    </Col>
                    <Col md>
                      <Form.Label>Image</Form.Label>
                      <Form.Control type="file" onChange={thumbnailChange} />
                      <p className="text-center mt-2">
                        * The image width and height should be 360px * 270px
                      </p>
                      <div>
                        {isproductthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={productthumb} height={100} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="card-footer mt-4">
                    <h3 className="text-center">Options</h3>
                  </div>
                  <div>
                    {options.map((details, index) => (
                      <div key={index}>
                        <Row className="g-2  m-2">
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Pack Name <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={details?.packName}
                              required
                              onChange={(e) =>
                                handleCheck(index, e, "packName")
                              }
                            />
                          </Col>
                          <Col md>
                            <Form.Label htmlFor="disabledTextInput">
                              Selling Price{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              required
                              value={details?.price}
                              onChange={(e) => handleCheck(index, e, "price")}
                            />
                          </Col>
                          <Col>
                            <div
                              class="checkbox-wrapper-6 d-flex"
                              style={{ flexDirection: "column" }}
                            >
                              <Form.Label className="mx-2">Prime</Form.Label>
                              <div>
                                <input
                                  className="tgl tgl-light"
                                  id="prime"
                                  type="radio"
                                  name="prime"
                                  value={details?.prime}
                                  checked={details?.prime === true}
                                  onChange={(e) =>
                                    handleCheck(index, e, "prime")
                                  }
                                />
                                <label
                                  className="tgl-btn"
                                  htmlFor="prime"
                                ></label>
                              </div>
                            </div>
                          </Col>
                          {options.length !== 1 && (
                            <Col md>
                              <Button
                                className="btn btn-danger"
                                style={{ marginTop: "32px" }}
                                onClick={(e) => removeRowClick(e, index)}
                              >
                                <div>
                                  <MdOutlineRemove />
                                </div>
                              </Button>
                            </Col>
                          )}
                        </Row>
                        <Row className="g-2  m-2"></Row>
                      </div>
                    ))}

                    <Button
                      className="btn  btn-primary m-3"
                      onClick={(e) => handleNewRow(e)}
                    >
                      Add More
                    </Button>
                  </div>
                </div>
                <div className="card-footer text-center ">
                  {!errorslug.length > 0 &&
                    categoryid !== "" &&
                    brand !== "" &&
                    product !== "" &&
                    !isproductthumbLoading &&
                    options.length >= 1 ? (
                    <button className="btn btn-primary me-2 " type="submit">
                      Add Product
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary me-2 "
                      disabled
                      type="submit"
                    >
                      Add Product
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
}

export default AddProduct;
