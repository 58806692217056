import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import {
  brandPost,
  validatebrandSlugUrl,
  resetBrandImage,
  brandAllimgageUploade,
} from "../../../redux/brand/brandSlice";

const AddBrand = () => {
  const {
    brandicon,
    brandbanner,
    branddesktop,
    brandthumb,
    isbrandthumbLoading,
  } = useSelector((store) => store.brandAll);
  const { categorytotal } = useSelector((store) => store.categoryAll);

  const [brand, setBrand] = useState("");
  const [slugUrl, setSlugUrl] = useState("");
  const [hot, setHot] = useState(Boolean(0));
  const [offer, setOffer] = useState(Boolean(0));
  const [partners, setPartners] = useState(Boolean(0));

  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [selectcat, setSelectcat] = useState([]);

  const [checkslugUrl, setCheckSlugUrl] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetBrandImage());
  }, []);

  const brandchange = async (e) => {
    const name = e.target.value;
    setBrand(name);
    let slugurl = name
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!/'{}()|[\]\\]/g, "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("--", "-");
    setSlugUrl(slugurl);
    if (slugurl != "") {
      let responce = await dispatch(validatebrandSlugUrl(slugurl));

      if (responce.payload.success) {
        setError("Brand alredy exist");
      } else {
        setCheckSlugUrl(slugurl);
        setError("");
      }
    }
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    if (brand !== "" && !isbrandthumbLoading && checkslugUrl !== "") {
      const formData = {
        name: brand.charAt(0).toUpperCase() + brand.slice(1).toLowerCase(),
        slugUrl: slugUrl,
        hot: hot,
        offer: offer,
        partners: partners,
        thumbnail: brandthumb,
        icon: brandicon,
        desktopicon: branddesktop,
        banner: brandbanner,
      };
      const data = await dispatch(brandPost(formData));
      if (!data) {
        alert("please check the flields");
      } else {
        if (data.payload.success) {
          alert("Brand Added");
          setBrand("");
          setSlugUrl("");
          setHot("");
          setOffer("");
          setPartners("");
        } else {
          alert("Please check your data");
        }
      }
    } else {
      alert("please enter valid details");
    }
  };

  const thumbnailChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(brandAllimgageUploade({ thumbnail: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <h4 className="p-4">Add Brand</h4>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Brand</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={brand}
                        required
                        onChange={(e) => brandchange(e)}
                      />
                      <p style={{ color: "red" }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label>Brand Thumbnail Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={thumbnailChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 200px * 200px
                      </p>
                      <div>
                        {isbrandthumbLoading ? (
                          <div></div>
                        ) : (
                          <img src={brandthumb} height={150} alt="img" />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="hot"
                          checked={hot === true}
                          onChange={(e) => {
                            setHot(e.target.checked);
                          }}
                        />
                        <Form.Label>Hot</Form.Label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="me-1"
                          value="offer"
                          checked={offer === true}
                          onChange={(e) => {
                            setOffer(e.target.checked);
                          }}
                        />
                        <Form.Label>Offer</Form.Label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          className="ml-3"
                          value="partners"
                          checked={partners === true}
                          onChange={(e) => {
                            setPartners(e.target.checked);
                          }}
                        />
                        <Form.Label>Partners</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {!isbrandthumbLoading && !error.length > 0 ? (
                    <button className="btn btn-primary" type="submit">
                      Add Brand
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Brand
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddBrand;
