import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./redux/category/categorySlice";
import adminReducer from "./redux/admin/adminSlice";
import brandReducer from "./redux/brand/brandSlice";
import authSlice from "./redux/authentication/authSlice";
import productReducer from "./redux/product/productSlice";
import courierPricesReducer from "./redux/courierRate/courierSlice";
import courierList from "./redux/addCourier/addCourierSlice";
import orderList from "./redux/productOrder/productOrderSlice";
import RequestCourierReducer from "./redux/courierRequest/courierRequestSlice";

export const store = configureStore({
  reducer: {
    categoryAll: categoryReducer,
    admin: adminReducer,
    brandAll: brandReducer,
    productAll: productReducer,
    auth: authSlice.reducer,
    courierPrices: courierPricesReducer,
    courierList: courierList,
    productOrder: orderList,
    CourierRequest: RequestCourierReducer,
  },
});
