import { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import axios from "axios";
import { Modal, ModalHeader } from "reactstrap";

export function ListProduct() {
  const [loadData, setLoadData] = useState([]);
  const [selectProduct, setSelectProduct] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);

  const dispatch = useDispatch();

  const { producttotal } = useSelector((store) => store.productAll);

  useEffect(() => {
    const product = [...producttotal].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );
    setLoadData(product);
  }, [producttotal]);

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      //   renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      // renderCell: (params) => indexOf(params.row) + 1,
      renderCell: (params) => loadData.findIndex(row => row._id === params.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productname",
      headerName: "PRODUCT NAME",
      width: 200,
      renderCell: (params) => params.row.name,
      headerAlign: "center",
    },
    // {
    //   field: "STATUS",
    //   width: 150,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div className="d-flex m-auto">
    //           <Col>
    //             <Form.Check
    //               type="switch"
    //               id="custom-switch"
    //               label=" "
    //               value={params.row.status}
    //               checked={params.row.status}
    //               //   onChange={(e) => statusChange(e, params.row)}
    //             />
    //           </Col>
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      field: "ACTION",
      width: 240,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto my-2">
            <Link to={`/admin/updateproduct/${params.row.slugUrl}`}>
              <Button variant="contained" color="primary">
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              //   onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div>
        <div className="content-wrapper ">
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={loadData}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              getRowHeight={() => "auto"}
              rowsPerPageOptions={[10]}
            // checkboxSelection
            />
          </div>

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectProduct !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectProduct.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                      Product
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                        //   onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
        </div>
      </div>
    </>
  );
}
